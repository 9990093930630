import React from 'react'  
// import {   NavLink } from 'react-router-dom' 
import '../../assest/CSS/Navbar.css';  
import comLogo from '../../assest/logo/QATIC Logo.png' 
import {Link} from 'react-scroll'
// import {MenuList} from './MenuList' 
import MenuIcon from '@material-ui/icons/Menu'; 
import CloseIcon from '@material-ui/icons/Close';
import { useState } from 'react';     


 
function Navbar() {    

  const [clicked, setClicked] = useState(false);  
  const handleClick = () => {
    setClicked(!clicked);
  } 

  // const navstylecolor = ({isActive}) => {
  //   return{  
  //     color: isActive ? '' : '#006241'
  //   }
  // }  
  
  // const menuList = MenuList.map(({url, title},index) => {
  //   return(
  //       <li key = {index} >
  //           <NavLink style={navstylecolor} to={url}>{title}</NavLink>
  //       </li>
  //   )});    

  
  return ( 

    <div className='nav_cont'>   
    <div className='logo_and_title'> 
    <div className='menu_hidden'>
        <span></span>
      </div>
    <div className='logo'> 
        <img src={comLogo} alt='company_logo' />
      </div>      
      <div className='menu'  onClick={handleClick}> 
      {clicked ?   <CloseIcon color='primary' /> :  <MenuIcon color='primary'/>  }
          </div>
      <div className='com_title'> 
        <p>Queens</p>
        <span>Agro Technology and Innovation Center </span>
      </div> 
            
      </div>     
    <>  
    {/*  Larger screen navbar */}
    <div  className='nav_right '>   
        {/* {menuList}    */}   
        {/* <li><a href='/'><Link activeClass='active' style to="contact"  spy={true} smooth={true} duration={500} >Contact</Link></a></li> */}
        {/* <li onClick={navstylecolor}><NavLink to={'/'}  style={navstylecolor}>Home</NavLink></li>  
        <li><NavLink to={'/'} style={navstylecolor}>Career</NavLink></li> 
        <li><NavLink to={'/'} style={navstylecolor}>Contact US</NavLink></li> 
        <li><NavLink to={'/'} style={navstylecolor}>About us</NavLink></li>    */}
         <li><Link activeClass='active' to="homeRef"  spy={true} smooth={true} offset={-70} duration={500} >Home</Link></li>  
         <li><Link activeClass='active' to="CareerRef"  spy={true} smooth={true} offset={-70} duration={500} >Career</Link></li>
        {/* <li><Link activeClass='active' to="contact"  spy={true} smooth={true} duration={500} >Contact Us</Link></li>   */} 
        <li><Link activeClass='active' to="formRef"  spy={true} smooth={true} offset={-70} duration={500} >Contact us</Link></li>  
        <li><Link activeClass='active' to="AboutusRef"  spy={true} smooth={true} offset={-70} duration={500} >About us</Link></li>  
       

        {/* <li><NavLink style={navstylecolor} to={"/"}>Career</NavLink></li> 
        <li><NavLink style={navstylecolor} to={"/"}>Contact Us</NavLink></li> 
        <li><NavLink style={navstylecolor} to={"/"}>About us</NavLink></li> */}
       
         
    </div>   
      {/*  mobile screen navbar */}
    <div  className={clicked ? 'mob ' : 'mob close'}  onClick={handleClick}>   
        {/* {menuList}    */}   
        <li><Link activeClass='active'onClick={handleClick} to="homeRef"  spy={true} smooth={true}offset={-70} duration={500} >Home</Link></li>  
        <li><Link activeClass='active'onClick={handleClick} to="CareerRef"  spy={true} smooth={true}offset={-70} duration={500} >Career</Link></li> 
         <li><Link activeClass to="formRef"  onClick={handleClick} spy={true} smooth={true} offset={-70} duration={500} >Contact us</Link></li> 
         <li><Link activeClass to="AboutusRef"  onClick={handleClick} spy={true} smooth={true} offset={-70} duration={500} >About us</Link></li>
       
        {/* <li><Link activeClass='active' to="contact"  spy={true} smooth={true} duration={500} >Contact Us</Link></li>   */} 
        
         
         
    </div>  
    </>  

    </div>
  )
}

export default Navbar