// import React, { useState,useEffect } from 'react' 
import React from 'react';

import '../../assest/CSS/HomeSections/SectionOne/sectionOne.css';
function HomeSectionOne() {   
  // const[currentIndex, SetCurrent] = useState(0); 
  // const myArray = ["Welcome to","QATIC"];
  // useEffect(() => {
  //   let slidertext = setInterval(() => { 
  //   //   const isFirstSlide = currentIndex === 0 
  //   // const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1; 
  //   const isLastSlide = currentIndex === myArray.length - 1;  
  //    const newIndex = isLastSlide ? 0 : currentIndex + 1; 
  //    console.log(newIndex)
  //   SetCurrent(newIndex);
  //   },1700);
  //   return () => clearInterval(slidertext)
  // },)  

  
  return (
    <div  id="homeRef" className='hero_Section'> 
          <div className='heroimg'> 
          <div className='layone'>
            <div className='laytwo'>  
              <div className='hero_cont'> 
                {/* <p>{myArray[currentIndex]}</p> */} 
                <p>QATIC</p>
                <span>A Growth in Farming</span>  
              </div>
              
            </div>
          </div>
          </div> 
          
         
    </div>
  )
}

export default HomeSectionOne