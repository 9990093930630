import React, { useState } from 'react'; 
import axios from 'axios';  
import Swal from 'sweetalert2'
import '../../assest/CSS/Contact/ContactStyle.css'  
const url = '/api/api/' 




function Contact() {   
  const [name,setName] = useState(''); 
  const [email,setemail] = useState('');   
  const[phone,setPhone] = useState('');
  const [subject,setsubject] = useState('');  
  const [content,setcontent] = useState('');   
  // const [loading,setLoading] = useState(tre);

 
  const handleSubmit = async (e) => {
    e.preventDefault();     
    try { 
      Swal.fire({
        title: 'Please  wait ', 
        html: 'submitting your Response',
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
        },
      })    
      const resp = await axios.post(url, {
        name:name, 
        email:email,  
        phone:phone,
        subject:subject, 
        content:content, 
      });    
      setName('')  
      setemail('') 
      setPhone('')     
      setsubject('')   
      setcontent('') 

      // setLoading(true); 
      Swal.fire({
            icon: 'success',
            title: 'Successfully submitted',
            // text: 'Something went wrong!',
            // footer: '<a href="">Why do I have this issue?</a>'
          })
      console.log(resp.data);  
      
    } catch (error) {
      console.log(error.resp)
    } 

    // const dialogloading = () => { 
    //   Swal.fire({
    //     title: 'Please  wait ', 
    //     html: 'submitting your Response',
    //     timerProgressBar: true,
    //     didOpen: () => {
    //       Swal.showLoading()
    //     },
    //   })  
      

    // } 
    // const dialogsuccess = () => { 
    //   Swal.fire({
    //     icon: 'success',
    //     title: 'Successfully submitted',
    //     // text: 'Something went wrong!',
    //     // footer: '<a href="">Why do I have this issue?</a>'
    //   })


    // }

    
  }
    
  return ( 

    <div id="formRef" className='bgcc'> 
        
     <div className='wrap  '>
    <div className='contact'>
      <div className='Title_contact'>
           <p>Contact us </p> 
     </div>  
          <div className='contact_form'> 
          <form   onSubmit={handleSubmit}  >
          <div className='contact_form_details'> 
            <label>Full Name</label>
            <input type='text'  value={name}  onChange={(e) => setName(e.target.value)}  pattern="[A-Za-Z]" maxLength='35'  title='Please Enter only letters required' required/>
          </div>  
          <div className='contact_form_details'> 
            <label>Email</label>
            <input type='email' title='please enter email'   value={email} onChange={(e)=> setemail(e.target.value)} />
          </div>   
          <div className='contact_form_details'> 
            <label>Phone</label>
            <input type='tel' pattern='[0-9]{10}' title='Enter 10-digit phone number'  value={phone} onChange={(e)=> setPhone(e.target.value)}  required/>
          </div>   
          <div className='contact_form_details'> 
            <label>Subject</label>
            <input type='text' maxLength='50' title='please enter only 50 characters' value={subject} onChange={(e)=> setsubject(e.target.value)}  required/>
          </div> 
  

          <div className='contact_form_details split'> 
          <label>Give  additional info  </label>
            {/* <input type='text' required /> */} 
            <textarea maxLength='750' placeholder="Leave your Message here.. " name='content' value={content} onChange={(e)=> setcontent(e.target.value)}   required></textarea>
            </div> 
            
            <div className='contact_form_details_button'>  
             
            <button  >Submit</button>
          </div> 
          </form>
          </div> 
          <div className='space'></div>
    </div>  
    </div> 
    </div>
    
  )
}

export default Contact