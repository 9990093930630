import React from 'react' 
import '../../assest/CSS/Career/career.css';

function Career() {
  return (
    <div  id="CareerRef" className='main_Career'>  
      <div className='text_Career'> 
       <p>Career News</p>
      </div> 
      <div className='career_content'> 
      <div className='box'> 
      <div className='title_date'>
        <span>Frontend Developer + UI UX</span> 
        {/* <p>15 Aug</p> */}
      </div> 
      <div className='quick_status'>
          
        <p>Remote Work</p> 
       
      </div> 

      <div className='description'>
        <p>Description:</p>  
        <span>React js  Hooks  and API</span> 
        <br></br>
        <small>Send Your CV to</small> 
        <h5>hrconnect@queensagrotech.com</h5>
      </div>

      </div> 
      <div className='box'>  
      <div className='title_date'>
      <span>Backend Developer</span> 
        {/* <p>15 Aug</p> */}
        
        </div>   
        <div className='quick_status'>
      
        <p>Remote Work</p> 
        {/* <p>No of Opening 1</p> */}
      </div> 
      <div className='description'>
      <p>Description:</p>  
        <span>Python, Django RestFrameWork API,SQl</span>  
        <br></br>
        <small>Send Your CV to</small> 
        <h5>hrconnect@queensagrotech.com</h5>
      </div>
        </div> 
         

      </div>
    </div>
  )
}

export default Career