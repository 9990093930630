import React from 'react'

function NotFounderror() {
  return (
    <div>
        <h1>Page Not Found</h1>
    </div>
  )
}

export default NotFounderror