import React from 'react'
import '../../assest/CSS/AboutUs/about.css'

const AboutUs = () => {
  return (
    <div id="AboutusRef" className='main_about' > 
     <div className='abouttitle'><p>About Us</p></div>  
     <div className='inner_about'>
        <p>A Startup Company to help local farmers to succeed in their farming and innovate traditional ideas. We will help the value add product to reach   everyone's home.
</p>
     </div>
    </div>
  )
}

export default AboutUs