import React from 'react'
import '../../assest/CSS/HomeSections/SectionTwo/sectiontwo.css'

const SectionTwo = () => { 
  // const [rndmItem, setRndmItem] = useState();  
  // const[currentIndex, SetCurrent] = useState(0);  


  // const myArray = ["Nature", "Technologies", "Food Production","IOT","Drone Technology","ML <#> AI"];

  // setTimeout(() => {
  //   var randomItem = myArray[Math.floor(Math.random() * myArray.length)]; 
  //   console.log(randomItem);
  //   setRndmItem(randomItem);
  // }, [4000]); 
  // useEffect(() => {
  //   let slidertext = setInterval(() => { 
  //   //   const isFirstSlide = currentIndex === 0 
  //   // const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1; 
  //   const isLastSlide = currentIndex === myArray.length - 1;  
  //    const newIndex = isLastSlide ? 0 : currentIndex + 1; 
  //    console.log(newIndex)
  //   SetCurrent(newIndex);
  //   },3700);
  //   return () => clearInterval(slidertext)
  // },) 
  return (
    <div className='sectiontwo'> 
        <div className='main_two'>
          <div className='sub_main'>
            <p>Our Service for Nature</p>
          </div>
        </div>
    </div>
  )
}

export default SectionTwo