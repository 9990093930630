import './App.css'; 
import React from 'react';
import {BrowserRouter as Router, Routes ,Route} from 'react-router-dom';
import Navbar from './Pages/Navbar/Navbar';   

import Home from './Pages/HomePage/Home';
// import Contact from './Pages/ContactPage/Contact';
import NotFounderror from './Pages/NotFoundPage/NotFounderror';
import Footer from './Pages/HomePage/Footer';
// import Ribbon from './Pages/Ribbon_message/Ribbon';
// import RequestQuoteForm from './Pages/RequestQuote/RequestQuoteForm';   
// const LazyRibbon = React.lazy(()=> import('./Pages/Ribbon_message/Ribbon'))
function App() {   


  return (
    <div className="App">  
    <Router>    
        <Navbar />    
        
        {/* <React.Suspense> <LazyRibbon />   </React.Suspense>    */}
       <Routes> 
      <Route path='/' element = {<Home />} />
      {/* <Route path='/Contact' element = {<Contact />} />      */}
      
      <Route path = '*' element = {<NotFounderror />} />
      </Routes>  
         <Footer/>
      </Router> 
     
      
    </div>
  );
}

export default App;
