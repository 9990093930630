import React from 'react'
import '../../assest/CSS/Footer/footerStyle.css'

function Footer() {
  return ( 
    <>
    <div className='footer'>
         {/* <div className='text'> 
         <div className='lay'>
            <p>Privacy Policy</p> 
            </div>  
            <div className='lay'>
                <p>Terms of Use</p>  
                </div>   
                <div className='lay'>
                <p>Sales Policy </p>  
                </div>  
                <div className='lay'>
                <p>Legal</p> 
                </div> 
                <div className='lay'> 
                <p>Site Map</p> 
                </div>
            </div> 
            <div className='location'>
                <p>India</p>
            </div>  */}
    </div> 
    <div className='footer_down'>
        <p> Registered in India MSME</p> 
        <div className='footer_reg'>
            <p>&#169;2022 Queens Agro Technology and Innovation Center.All rights reserved.</p>
        </div>
    </div>
    </>
  )
}

export default Footer