import React from 'react'
import '../../assest/CSS/HomeSections/sectionThree/sectionthree.css'

function HomeSectionThree() {
  return (
    <div  className='sectionthree'>
        <div className='card'>  
        {/* one */}
        <div className='imgone'>
          <div className='main_imgtxt'> 
          <div className='imgtxt'> 
          <p>We grow food for future</p></div> 
          

          </div>
        </div>
        </div>   
       
        <div className='card'> 

        <div className='imgtwo'>  
        <div className='main_imgtxt'> 
          <div className='imgtxt'> 
          <p>We support farmers</p></div> 
          

          </div>
              
           
                
                </div>
            </div>
            <div className='card'>  

            <div className='imgthree'> 
            <div className='main_imgtxt'> 
          <div className='imgtxt'> 
          <p>We add values to your harvest</p></div> 
          

          </div>
              

                </div>
             
            </div>
            <div className='card'>  
            {/*  */}
            <div className='imgfour'> 
            <div className='main_imgtxt'> 
          <div className='imgtxt'> 
          <p>We help you to grow yourself</p></div> 
          

          </div>
              

            </div>
           
            </div>
            <div className='card'> 
            {/*  */}
            <div className='imgfive'> 
            <div className='main_imgtxt'> 
          <div className='imgtxt'> 
          <p>Research on climatic change and solutions</p></div> 
          

          </div>
              

</div>
            </div>  
            <div className='card'> 
            {/*  */}
            <div className='imgsix'> 
            <div className='main_imgtxt'> 
          <div className='imgtxt'> 
          <p>We analyse with you in the field</p></div> 
          

          </div>
              

</div>
            </div> 
            
    </div>
  )
}

export default HomeSectionThree