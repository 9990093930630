import React from 'react'  
import SectionTwo from './SectionTwo'
import HomeSectionOne from './HomeSectionOne'  
import HomeSectionThree from './HomeSectionThree'
import Contact from '../ContactPage/Contact'
import Career from './Career'
import AboutUs from './AboutUs'



function Home() {
  return (
     <div className='home'> 
      <HomeSectionOne /> 
      <SectionTwo />     
      <HomeSectionThree />  
      <Career />
      <Contact/> 
      <AboutUs/>
      
       
     
    
    </div>  
    
     
     

  )
}
export default Home